@import "../../styles/variables";

.course-listing {
  margin: 0 60px 100px;

  .course-title {
    font-family: $title-font;
    text-transform: uppercase;
  }
}
.course-wrapper {
  @include flexbox;
  @include flex-direction(row);
  @include flex-wrap(wrap);
  @include justify-content(center);

  gap: 1em;
  margin: 20px 0;

  .course-item {
    @include flex(1 1 100%);
  }
}

@media (min-width: $tab) {
  .course-wrapper {
    .course-item {
      @include flex-basis(calc((100% / 2) - 1rem));
    }
  }
}

@media (min-width: $desk) {
  .course-listing {
    margin-right: 100px;
    margin-left: 100px;

    .course-title {
      font-size: 50px;
      line-height: 74px;
    }
  }
  .course-wrapper {
    .course-item {
      @include flex-basis(calc((100% / 3) - 2rem));
    }
  }
}

@media (min-width: $widestdesk) {
  .course-wrapper {
    .course-item {
      @include flex-basis(calc((100% / 5) - 4rem));
      max-width: 305px;
      min-width: 290px;
    }
  }
}
