@import "../../styles/variables";

.user-page {
  .title-section {
    .user-name {
      font-family: $title-font;
      font-style: normal;
      font-weight: normal;
      font-size: 100px;
      line-height: 110px;
      text-transform: uppercase;
    }
  }

  .user-course {
    display: block;

    .header-section {
      h3 {
        margin: 5px 0;

        &.course-description {
          max-width: 464px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: $main-gray;
          opacity: 0.5;
          margin: 40px auto;
        }
      }
    }

    .body-section {
      .course-item {
        max-width: 100%;
        padding-top: 120px;
        height: fit-content;
        min-height: fit-content;
        position: relative;
        margin-bottom: 20px;

        .content-link {
          .content-wrapper {
            @include flexbox;
            @include flex-direction(row);
            @include flex-wrap(wrap);
            @include align-items(baseline);

            .content-section {
              display: block;
              width: 100%;
              min-height: inherit;
            }

            .button-section {
              width: 100%;
            }
          }

          &:hover {
            .content-wrapper {
              .content-section {
                background: none;

                .description {
                  visibility: visible;
                }
              }
            }
          }
        }

        .rank-wrapper {
          @include flexbox;
          @include flex-direction(row);
          @include flex-wrap(nowrap);
          @include align-items(center);

          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 120px;

          .course-rank {
            position: relative;
            top: inherit;
            right: inherit;
            width: 48%;
            font-family: $title-font;
            font-style: normal;
            font-weight: normal;
            font-size: 50px;
            line-height: 74px;
            text-transform: uppercase;
            color: $main-gray;
            height: auto;
            border-radius: 0;
            background-color: inherit;
            text-align: right;
            padding-right: 15px;
          }

          .btn-wrapper {
            width: 48%;
            display: block;
            padding-left: 15px;

            .prev-btn,
            .next-btn {
              width: 45px;
              height: 45px;
              filter: drop-shadow(-2px 4px 15px rgba(0, 0, 0, 0.1));
              background: $white;
              @include border-radius(50%);
              margin: 0;

              img {
                width: 20px;
                height: auto;
                margin: 16px 12px;
              }
            }

            .prev-btn {
              margin-bottom: 20px;
            }
          }
        }

        &:first-child {
          .rank-wrapper {
            .btn-wrapper {
              .next-btn {
                display: none;
              }

              .prev-btn {
                margin-bottom: 0;
              }
            }
          }
        }

        &:nth-child(5) {
          .rank-wrapper {
            .btn-wrapper {
              .prev-btn {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .button {
    margin: 80px auto 100px;
  }
}

.all-course {
  .title {
    font-family: $title-font;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 74px;
    text-align: center;
    text-transform: uppercase;
  }
}

@media (min-width: $desk) {
  .user-page {
    .user-course {
      .body-section {
        .course-item {
          padding-left: 164px;
          padding-top: 0;

          .content-link {
            .content-wrapper {
              @include flexbox;
              @include flex-direction(row);
              @include flex-wrap(nowrap);
              @include align-items(baseline);

              .content-section {
                display: block;
                width: 78%;
                min-height: inherit;
              }

              .button-section {
                width: 18%;
              }
            }
          }

          .rank-wrapper {
            width: 164px;
            height: 100%;

            .course-rank {
              text-align: center;
              padding-right: 0;
            }

            .btn-wrapper {
              padding-left: 0;

              .prev-btn,
              .next-btn {
                margin: 0 auto;
              }

              .prev-btn {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}