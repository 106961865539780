
/* Border Radius */
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}
/* Box Sizing */
@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model;
  -moz-box-sizing: $box-model;
  box-sizing: $box-model;
}
/* Box shadow */
@mixin box-shadow($properties) {
  -webkit-box-shadow: $properties;
  -moz-box-shadow: $properties;
  box-shadow: $properties;
}
/* Appearance */
@mixin appearance($style) {
  -webkit-appearance: $style;
  -moz-appearance: $style;
  appearance: $style;
}
/* Transitions */
@mixin transition($properties) {
  -webkit-transition: $properties;
  -moz-transition: $properties;
  -o-transition: $properties;
  transition: $properties;
}
/* Transform */
@mixin transform($properties) {
  -webkit-transform: $properties;
  -moz-transform: $properties;
  -o-transform: $properties;
  transform: $properties;
}
/* Transform origin */
@mixin transform-origin($properties) {
  -webkit-transform-origin: $properties;
  -moz-transform-origin: $properties;
  -o-transform-origin: $properties;
  transform-origin: $properties;
}
/* Background size */
@mixin bg-size ($size) {
  -webkit-background-size: $size;
  -moz-background-size: $size;
  -o-background-size: $size;
  background-size: $size;
}
/* Placeholder */
@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}
/* Opacity */
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}
@mixin clearfix() {
  &::after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}
/* Clearfix */
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
/* Gradient */
@mixin gradient($from-color, $to-color, $direction: top) {
  background-color: $from-color; // Fallback
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from-color), to($to-color));
  background-image: -webkit-linear-gradient($direction, $from-color, $to-color);
  background-image:    -moz-linear-gradient($direction, $from-color, $to-color);
  background-image:     -ms-linear-gradient($direction, $from-color, $to-color);
  background-image:      -o-linear-gradient($direction, $from-color, $to-color);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$from-color}', EndColorStr='#{$to-color}')";
}
/* Font-awesome element */
@mixin fa-element {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Background clip */
@mixin background-clip($properties) {
  background-clip: $properties;
  -webkit-background-clip: $properties;
}
