@import "variables.scss";

body {
  height: auto;
  color: $main-gray;
  font-family: $body-font;
  font-size: 20px;
  line-height: 30px;
  font-style: normal;
  font-weight: 400;
  background-color: $bg-gray;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  margin: 0;

  #root {
    position: relative;
    overflow: hidden;

    .App {
      .page-wrapper {
        &.outer-wrapper {
        }

        .description {
          .description-title {
            font-family: $title-font;
            text-transform: uppercase;
          }
          .description-body {
            text-align: justify;
          }
        }
      }
    }
  }

  .button {
    width: max-content;
    padding: 7px 30px;
    background-color: $white;
    filter: drop-shadow(-2px 4px 15px rgba(0, 0, 0, 0.1));
    border-radius: 30px;

    a {
      @include flexbox;
      @include flex-direction(row);
      @include flex-wrap(nowrap);
      @include align-items(center);

      font-family: $title-font;
      font-size: 24px;
      line-height: 36px;
      text-transform: uppercase;
      color: $main-gray;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .course-title {
    font-family: $title-font;
    text-transform: uppercase;
  }

  .outer-wrapper {
    margin-right: 60px;
    margin-left: 60px;
  }
}

@media (min-width: $desk) {
  body {
    #root {
      .App {
        .page-wrapper {
          &.outer-wrapper {

          }

          .description {
            margin: 0 auto;
            .description-title {
              font-size: 50px;
              line-height: 74px;
            }
          }
        }
      }
    }

    .course-title {
      font-size: 50px;
      line-height: 74px;
    }

    .outer-wrapper {
      margin-right: 100px;
      margin-left: 100px;
    }
  }
}

@media (min-width: $widestdesk) {
  body {
    .outer-wrapper {
      max-width: $desk;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

@import "main";
