@import "../../styles/variables";

.login-wrapper {
  margin: 100px auto;
  padding: 0 60px;
  max-width: 465px;

  .logo-wrapper {
    padding-bottom: 70px;

    .header-logo {
      img {
        height: 80px;
        width: auto;
      }
    }
  }

  .content-section {
    text-align: center;

    .button {
      margin: 10px auto;

      &.disactiv {
        background-color: inherit;
        opacity: 0.7;
      }

      &.google-button {
        a {

          &::after {
            background: url('../../assets/images/google-icon.svg') no-repeat;
            background-size: 40px 40px;
            display: inline-block;
            width: 40px;
            height: 40px;
            content:"";
            margin-left: 15px;
          }
        }
      }
    }

    .help {
      font-size: 14px;
      opacity: 0.7;

      a {
        font-weight: bold;
      }
    }
  }
}

.login-form-wrapper {
  @include flexbox;
  @include flex-direction(column);

  input {
    padding: 12px;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 2px solid $main-gray;
    margin-top: 6px;
    margin-bottom: 16px;
    background-color: inherit;
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;
    color: $main-gray;
  }
}

@media (min-width: $widemob) {
  .header-wrapper {
    .banner-wrapper {
      .banner-image {
        img {
          max-height: 552px;
        }
      }
    }
  }
}

@media (min-width: $tab) {
  .header-wrapper {
    .banner-wrapper {
      .banner-image {
        text-align: right;

        img {
          width: 70%;
          border-radius: 50% 0 0 50%;
        }
      }

      .banner-title {
        position: absolute;
        top: 12%;
        margin-left: 90px;
      }
    }
  }
}

@media (min-width: $desk) {
  .header-wrapper {
    .banner-wrapper {
      .banner-image {
        img {
          width: 61%;
          max-height: 680px;
        }
      }

      .banner-title {
        font-size: 34px;
        line-height: 40px;
        margin-left: 120px;
      }
    }
    .header-logo-wrapper {
      .header-logo {
        img {
          width: 115px;
          margin: 25px 10px;
        }
      }
    }

    &.without-banner {
      padding-bottom: 120px;
    }
  }
}

@media (min-width: $widedesk) {
  .header-wrapper {
    .banner-wrapper {
      .banner-image {
        img {
          max-height: 810px;
        }
      }
    }
  }
}

@media (min-width: $widestdesk) {
  .header-wrapper {
    .banner-wrapper {
      .banner-image {
        img {
          max-height: 1080px;
        }
      }

      .banner-title {
        font-size: 50px;
        line-height: 52px;
        margin-left: 130px;
      }
    }

    &.without-banner {
      padding-bottom: 200px;
    }
  }
}
