@import "../../styles/variables";

.header-wrapper {
  position: relative;

  .header-logo-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;

    .header-logo {
      img {
        width: 90px;
        height: auto;
        margin: 25px 10px;
      }
    }
  }

  .banner-wrapper {
    position: relative;

    .banner-image {
      img {
        width: 100%;
        height: auto;
        max-height: 452px;
        object-fit: cover;
        box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.15);
      }
    }

    .banner-title {
      text-align: left;
      margin-left: 59px;
      h1 {
        font-family: $title-font;
        text-transform: uppercase;
      }
      .google-button {
        width: max-content;
        padding: 7px 30px;
        background-color: $white;
        filter: drop-shadow(-2px 4px 15px rgba(0, 0, 0, 0.1));
        border-radius: 30px;

        a {
          @include flexbox;
          @include flex-direction(row);
          @include flex-wrap(nowrap);
          @include align-items(center);

          font-family: $title-font;
          font-size: 24px;
          line-height: 36px;
          text-transform: uppercase;
          color: $main-gray;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }

          &::after {
            background: url('../../assets/images/google-icon.svg') no-repeat;
            background-size: 40px 40px;
            display: inline-block;
            width: 40px;
            height: 40px;
            content:"";
            margin-left: 15px;
          }
        }
      }
    }
  }

  &.without-banner {
    padding-bottom: 70px;

    .header-logo-wrapper {
      position: relative;
      text-align: left;
    }
    .title-section {
      .header-title {
        font-size: 100px;
        line-height: 110px;
        font-family: $title-font;
        text-transform: uppercase;
      }
    }
  }
}

@media (min-width: $widemob) {
  .header-wrapper {
    .banner-wrapper {
      .banner-image {
        img {
          max-height: 552px;
        }
      }
    }
  }
}

@media (min-width: $tab) {
  .header-wrapper {
    .banner-wrapper {
      .banner-image {
        text-align: right;

        img {
          width: 70%;
          border-radius: 50% 0 0 50%;
        }
      }

      .banner-title {
        position: absolute;
        top: 12%;
        margin-left: 90px;
      }
    }
  }
}

@media (min-width: $desk) {
  .header-wrapper {
    .banner-wrapper {
      .banner-image {
        img {
          width: 61%;
          max-height: 680px;
        }
      }

      .banner-title {
        font-size: 34px;
        line-height: 40px;
        margin-left: 120px;
      }
    }
    .header-logo-wrapper {
      .header-logo {
        img {
          width: 115px;
          margin: 25px 10px;
        }
      }
    }

    &.without-banner {
      padding-bottom: 120px;
    }
  }
}

@media (min-width: $widedesk) {
  .header-wrapper {
    .banner-wrapper {
      .banner-image {
        img {
          max-height: 810px;
        }
      }
    }
  }
}

@media (min-width: $widestdesk) {
  .header-wrapper {
    .banner-wrapper {
      .banner-image {
        img {
          max-height: 1080px;
        }
      }

      .banner-title {
        font-size: 50px;
        line-height: 52px;
        margin-left: 130px;
      }
    }

    &.without-banner {
      padding-bottom: 200px;
    }
  }
}
