@import "../../styles/variables";

.footer-wrapper {
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(center);
  @include align-items(center);

  margin: 20px 60px;

  .footer-column {
    width: 100%;
    max-width: max-content;
    margin: 30px 0;
    text-align: left;

    &.logo-section {
      text-align: center;
    }

    a {
      display: block;
      font-weight: bold;
      color: $main-gray;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &.email {
        @include flexbox;
        @include flex-direction(row);
        @include flex-wrap(nowrap);
        @include align-items(center);

        &::before {
          background: url('../../assets/images/email-icon.svg') no-repeat;
          background-size: 15px 18px;
          display: inline-block;
          width: 15px;
          height: 18px;
          content:"";
          margin-right: 5px;
        }
      }

      &.telegram {
        @include flexbox;
        @include flex-direction(row);
        @include flex-wrap(nowrap);
        @include align-items(center);

        &::before {
          background: url('../../assets/images/telegram.svg') no-repeat;
          background-size: 15px 18px;
          display: inline-block;
          width: 15px;
          height: 18px;
          content:"";
          margin-right: 5px;
        }
      }
    }

    h3 {
      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;
      font-family: $title-font;
      margin: 0 0 20px 0;
    }

    p, a {
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 5px;
    }

    .support {
      margin-bottom: 30px;
    }

    .footer-logo {
      margin-bottom: 40px;

      img {
        width: 115px;
        height: auto;
      }
    }

    .footer-khai {
      margin-bottom: 40px;

      img {
        width: 56px;
        height: auto;
      }
    }
  }
}

@media (min-width: $desk) {
  .footer-wrapper {
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include justify-content(space-between);

    margin: 20px 100px;

    .footer-column {
      @include flex-basis(calc((100% / 3) - 2rem));
    }
  }
}