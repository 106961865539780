@import "../../styles/variables";

.search-wrapper {
  position: relative;
  padding-bottom: 70px;

  .header-logo-wrapper {
    position: relative;
    text-align: left;

    .header-logo {
      img {
        width: 90px;
        height: auto;
        margin: 25px 10px;
      }
    }
  }

  .search-section {
    .inner-wrapper {
      @include flexbox;
      @include flex-direction(row);
      @include flex-wrap(nowrap);
      @include justify-content(space-between);

      border-bottom: 2px solid $main-gray;
      max-width: 450px;
      margin: 200px auto 60px auto;

      span {
        img {
          width: 15px;
          height: 15px;
        }
      }
    }


    .reset-button {
      font-size: 0;
      border: none;
      outline: none;
      width: 25px;
      height: 25px;
      background: url('../../assets/images/reset-icon.svg') no-repeat;
      background-size: 25px 25px;
    }

    .search-input {
      width: 100%;
      background: none;
      outline: none;
      box-shadow: none;
      border: none;
      font-size: 10px;
      padding-left: 10px;

      &::placeholder {
        opacity: 0.7;
        text-transform: uppercase;
        font-size: 10px;
      }
    }

    .search-result {
      .search-result-inner-wrapper {
        max-width: 450px;
        margin: 0 auto;

        .search-count {

        }
        .search-table {
          height: 700px;
          overflow-x: hidden;
          overflow-y: scroll;

          .search-item {
            padding: 25px 60px;
            background-color: $white;

            &:nth-child(2n) {
              background-color: inherit;
            }

            .content-section {
              @include flexbox;
              @include flex-direction(row);
              @include flex-wrap(nowrap);
              @include justify-content(space-between);


              .body-section {
                h5 {
                  font-size: 30px;
                  line-height: 44px;
                  font-family: $title-font;
                  text-transform: uppercase;
                  margin: 0;
                }
                p {
                  padding-left: 10px;
                  font-size: 20px;
                  line-height: 24px;
                }
              }
              .button-section {
                text-align: center;
                padding: 30px 20px;

                .detailed-button {
                  font-family: $title-font;
                  font-size: 24px;
                  line-height: 36px;
                  text-transform: uppercase;
                  color: $main_gray;
                  text-decoration: underline;

                  &::after {
                    background-image: url('../../assets/images/icon-next.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    width: 24px;
                    height: 21px;
                    background-size: 24px;
                    content:"";
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: $desk) {
  .search-wrapper {
    padding-bottom: 120px;

    .header-logo-wrapper {
      .header-logo {
        img {
          width: 115px;
          margin: 25px 10px;
        }
      }
    }

    .search-section {
      .inner-wrapper {
        max-width: 950px;

        span {
          img {
            width: 35px;
            height: 35px;
          }
        }
      }


      .reset-button {
        width: 45px;
        height: 45px;
        background: url('../../assets/images/reset-icon.svg') no-repeat;
        background-size: 45px 45px;
      }

      .search-input {
        font-size: 18px;
        padding-left: 20px;

        &::placeholder {
          font-size: 30px;
        }
      }

      .search-result {
        .search-result-inner-wrapper {
          max-width: 950px;
        }
      }
    }
  }
}

@media (min-width: $widestdesk) {
  .search-wrapper {
    padding-bottom: 200px;
  }
}
