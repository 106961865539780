@import "../../../styles/variables";

.course-detailed {
  .title-section {
    @include flexbox;
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include align-items(center);

    text-align: left;

    a {
      width: 45px;
      height: 45px;
      background-color: $white;
      border-radius: 50%;
      font-size: 0;

      &::after {
        background: url('../../../assets/images/icon-prev.svg') no-repeat bottom 14px right 18px;
        background-size: 10px 18px;
        display: inline-block;
        width: 45px;
        height: 45px;
        content:"";
      }
    }
    h2 {
      display: inline-block;
      padding-left: 10px;
    }
  }
  .body-section {
    text-align: justify;
  }
  .button {
    max-width: 100px;
    margin: 0 auto;
  }
}

.lecturer-wrapper {
  .image-section {
    img {
      width: 100%;
      height: auto;
      max-width: 365px;
      margin: 0 auto;
    }
  }
  .body-section {
    .title {
      font-family: $title-font;
      font-size: 30px;
      line-height: 40px;
      text-transform: uppercase;
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  opacity: 0;
  @include transition(opacity 400ms ease-in);
  pointer-events: none;

  &:target {
    opacity: 1;
    pointer-events: auto;
    overflow-y: auto;
  }
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10%;
}

.modal-content {
  position: relative;
  @include flexbox;
  @include flex-direction(column);
  @include background-clip(padding-box);
  background-color: $white;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 15px;
  outline: 0;
}

.modal-header {
  @include flexbox;
  @include align-items(center);
  @include justify-content(flex-end);
  padding: 15px;
}

.modal-title {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.25rem;
  font-weight: 500;
}

.close {
  float: right;
  font-size: 45px;
  width: 45px;
  height: 45px;
  font-weight: 700;
  line-height: 1;
  color: $black;
  text-shadow: 0 1px 0 $white;
  opacity: .5;
  text-decoration: none;
  @include border-radius(50%);
  @include box-shadow(-2px 4px 15px rgba(0, 0, 0, 0.1));

  &:focus,
  &:hover {
    color: $black;
    text-decoration: none;
    cursor: pointer;
    opacity: .75;
  }
}

.modal-body {
  position: relative;
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(flex-start);
  margin: 0 auto;
  width: auto;
  max-width: 630px;
  padding: 15px;
  overflow: auto;
  text-align: left;

  .course-title {
    margin-top: 0;
  }

  .choose-pr-wrapper {
    @include flexbox;
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include justify-content(center);

    padding: 25px 0;

    .choose-item {
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
      @include border-radius(50%);
      @include box-shadow(-2px 4px 15px rgba(0, 0, 0, 0.1));

      &.inactive {
        opacity: .2;
      }
      &.active {
        background-color: $blue;
        color: $white;
      }
    }
  }

  span {
    font-size: 25px;
    width: 45px;
    height: 45px;
    font-weight: 700;
    color: $black;
    text-shadow: 0 1px 0 $white;
    opacity: .5;
    text-decoration: none;
    @include border-radius(50%);
    @include box-shadow(-2px 4px 15px rgba(0, 0, 0, 0.1));
    display: block;
    text-align: center;
    line-height: 45px;
  }
}

@media (min-width: $widemob) {
  .modal-dialog {
    max-width: 950px;
    margin: 10% auto 0;
  }
}

@media (min-width: $tab) {
  .lecturer-wrapper {
    @include flexbox;
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include justify-content(space-between);

    .image-section {
      width: 30%;
      margin-right: 30px;
    }
    .body-section {
      width: calc(70% - 30px);
      .title {
        margin-top: 0;
      }
    }
  }
  .modal-content {
    @include box-shadow(0 5px 15px rgba(0, 0, 0, .5));
  }
}
