@import "flex_mixins.scss";
@import "site_mixins.scss";

$title-font: 'Oswald', sans-serif;
$body-font: 'Montserrat', sans-serif;

$main-gray: #4B4B4B;
$bg-gray: #F8F7F7;

$black: #000;
$white: #fff;
$blue: #08B6DC;

// 1em = 16px
$mobile: 22em; // 352px
$widemob: 30em; // 480px
$widestmob-max: 639px;
$widestmob: 40em; // 640px
$tab-max: 767px;
$tab: 48em; // 768px
$desk-max: 979px;
$desk: 61.25em; // 980px
$mediumdesk: 68.75em; // 1100px
$widedesk: 80em; // 1280px
$widestdesk: 87.5em; // 1400px