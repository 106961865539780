@import "../../styles/variables";

.nav-wrapper {
  width: 59px;
  position: relative;

  .icon-wrappers {
    position: fixed;
    left: 0;
    top: 12%;
    z-index: 2;

    ul {
      list-style: none;
      padding: 0;

      display: flex;
      flex-direction: column;
      justify-content: center;

      li {
        a:hover {
          img {
            background-color: $white;
          }
        }
        img {
          height: auto;
          width: 25px;
          padding: 17px;
        }
      }
    }
  }
}

@media (min-width: $widemob) {
  .nav-wrapper {
    .icon-wrappers {
      top: 22%;
    }
  }
}

@media (min-width: $tab) {
  .nav-wrapper {
    .icon-wrappers {
      bottom: 25%;
      top: inherit;
    }
  }
}

@media (min-width: $desk) {
  .nav-wrapper {
    .icon-wrappers {
      bottom: 10%;

      ul {
        li {
          img {
            width: 46px;
            padding: 26px;
          }
        }
      }
    }
  }
}

@media (min-width: $widestdesk) {
  .nav-wrapper {
    .icon-wrappers {
      bottom: 2%;
    }
  }
}
