@import "../../../styles/variables";

.course-item {
  margin: 0;
  text-align: left;
  background: $white;
  height: fit-content;
  min-height: 400px;
  border-radius: 10px;
  filter: drop-shadow(-2px 4px 15px rgba(0, 0, 0, 0.1));
  position: relative;

  .content-link {
    &:hover {
      .content-wrapper {
        .content-section {
          background: url("../../../assets/images/course_item.png") no-repeat;
          @include bg-size(auto 100%);
          object-fit: cover;
          border-radius: 10px 10px 0 0;

          .description {
            visibility: hidden;
          }
        }
      }
    }
    .content-wrapper {
      .content-section {
        padding: 30px 20px;
        min-height: 300px;

        @include flexbox;
        @include align-items(center);
      }
      .button-section {
        text-align: center;
        padding: 30px 20px;

        .detailed-button {
          font-family: $title-font;
          font-size: 24px;
          line-height: 36px;
          text-transform: uppercase;
          color: $main_gray;
          text-decoration: underline;

          &::after {
            background-image: url('../../../assets/images/icon-next.svg');
            background-repeat: no-repeat;
            background-position: center center;
            width: 24px;
            height: 21px;
            background-size: 24px;
            content:"";
            display: inline-block;
          }
        }
      }
    }
  }

  .course-rank {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: $blue;
    color: $white;
    line-height: 25px;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
  }

  .btn-wrapper {
    display: none;
  }
}
